
export default [
  {
    id: "1-1",
    title: "原価計算の流れ",
    categoryId: 1,
    page: 46
  },
  {
    id: "2-1",
    title: "材料費の分類",
    categoryId: 2,
    page: 46
  },
  {
    id: "2-2",
    title: "材料費の処理",
    categoryId: 2,
    page: 46
  },
  {
    id: "2-3",
    title: "材料費の計算",
    categoryId: 2,
    page: 47
  },
  {
    id: "2-4",
    title: "材料の棚卸減耗",
    categoryId: 2,
    page: 48
  },
  {
    id: "2-5",
    title: "予定消費単価を用いた場合",
    categoryId: 2,
    page: 48
  },
  {
    id: "3-1",
    title: "労務費の分類",
    categoryId: 3,
    page: 49
  },
  {
    id: "3-2",
    title: "労務費の処理①",
    categoryId: 3,
    page: 49
  },
  {
    id: "3-3",
    title: "労務費の処理②",
    categoryId: 3,
    page: 50
  },
  {
    id: "3-4",
    title: "予定賃率を用いた場合",
    categoryId: 3,
    page: 50
  },
  {
    id: "4-1",
    title: "経費の分類",
    categoryId: 4,
    page: 50
  },
  {
    id: "4-2",
    title: "経費の処理",
    categoryId: 4,
    page: 51
  },
  {
    id: "5-1",
    title: "個別原価計算と製造間接費の配賦",
    categoryId: 5,
    page: 51
  },
  {
    id: "5-2",
    title: "製造間接費の予定配賦",
    categoryId: 5,
    page: 52
  },
  {
    id: "5-3",
    title: "個別原価計算と製造間接費の予定配賦",
    categoryId: 5,
    page: 53
  },
  {
    id: "5-4",
    title: "製造間接費の予定配賦",
    categoryId: 5,
    page: 55
  },
  {
    id: "5-5",
    title: "個別原価計算",
    categoryId: 5,
    page: 56
  },
  {
    id: "6-1",
    title: "直接配賦法",
    categoryId: 6,
    page: 60
  },
  {
    id: "6-2",
    title: "相互配賦法",
    categoryId: 6,
    page: 62
  },
  {
    id: "6-3",
    title: "製造部門費の製品への配賦",
    categoryId: 6,
    page: 63
  },
  {
    id: "6-4",
    title: "製造部門費の予定配賦",
    categoryId: 6,
    page: 64
  },
  {
    id: "6-5",
    title: "部門別個別原価計算",
    categoryId: 6,
    page: 65
  },
  {
    id: "7-1",
    title: "単純総合原価計算（月初仕掛品なし）",
    categoryId: 7,
    page: 67
  },
  {
    id: "7-2",
    title: "単純総合原価計算（先入先出法）",
    categoryId: 7,
    page: 68
  },
  {
    id: "7-3",
    title: "単純総合原価計算（平均法）",
    categoryId: 7,
    page: 69
  },
  {
    id: "8-1",
    title: "工程別総合原価計算",
    categoryId: 8,
    page: 70
  },
  {
    id: "8-2",
    title: "等級別総合原価計算",
    categoryId: 8,
    page: 71
  },
  {
    id: "8-3",
    title: "組別総合原価計算",
    categoryId: 8,
    page: 73
  },
  {
    id: "9-1",
    title: "仕損・減損の処理①",
    categoryId: 9,
    page: 75
  },
  {
    id: "9-2",
    title: "仕損・減損の処理②",
    categoryId: 9,
    page: 77
  },
  {
    id: "9-3",
    title: "仕損・減損の処理③",
    categoryId: 9,
    page: 79
  },
  {
    id: "9-4",
    title: "材料の追加投入",
    categoryId: 9,
    page: 81
  },
  {
    id: "9-5",
    title: "単純総合原価計算＋仕損の処理＋材料の追加投入",
    categoryId: 9,
    page: 82
  },
  {
    id: "10-1",
    title: "工業簿記における財務諸表①",
    categoryId: 10,
    page: 84
  },
  {
    id: "10-2",
    title: "工業簿記における財務諸表②",
    categoryId: 10,
    page: 86
  },
  {
    id: "10-3",
    title: "工業簿記における財務諸表③",
    categoryId: 10,
    page: 88
  },
  {
    id: "11-1",
    title: "本社工場会計①",
    categoryId: 11,
    page: 90
  },
  {
    id: "11-2",
    title: "本社工場会計②",
    categoryId: 11,
    page: 92
  },
  {
    id: "12-1",
    title: "パーシャル・プランとシングル・プラン",
    categoryId: 12,
    page: 93
  },
  {
    id: "12-2",
    title: "標準原価計算の勘定記入",
    categoryId: 12,
    page: 96
  },
  {
    id: "13-1",
    title: "直接材料費差異の分析",
    categoryId: 13,
    page: 98
  },
  {
    id: "13-2",
    title: "直接労務費差異の分析",
    categoryId: 13,
    page: 99
  },
  {
    id: "13-3",
    title: "製造間接費差異の分析",
    categoryId: 13,
    page: 100
  },
  {
    id: "13-4",
    title: "標準原価計算の差異分析",
    categoryId: 13,
    page: 103
  },
  {
    id: "14-1",
    title: "直接原価計算の損益計算書",
    categoryId: 14,
    page: 106
  },
  {
    id: "14-2",
    title: "全部原価計算と直接原価計算の損益計算書",
    categoryId: 14,
    page: 107
  },
  {
    id: "14-3",
    title: "CVP分析①",
    categoryId: 14,
    page: 109
  },
  {
    id: "14-4",
    title: "CVP分析②",
    categoryId: 14,
    page: 112
  },
  {
    id: "14-5",
    title: "予算実績差異分析",
    categoryId: 14,
    page: 113
  },
  {
    id: "14-6",
    title: "高低点法",
    categoryId: 14,
    page: 115
  },
]